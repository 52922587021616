import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  B1,
  B2,
  B3,
} from "../../assets/images";




const HeroData = [
  {
    id:1,
    imgSrc: B1,
    subtitle: "Experience the",
    title: "WIRELESS EARBUDS",
    title2:
      "Discover our Signature Sound",
    buttonLink: "/shop",
    buttonText: "Explore Now",
    bgColor: "orange",
  },
  {
    id:2,
    imgSrc: B2,
    subtitle: "Explore the Latest Range",
    title: "PARTY SPEAKERS",
    title2:
      "Maximize creativity with our Next Gen Technology.",
    buttonLink: "/shop",
    buttonText: "Discover More..",
    bgColor: "orange",
  },
  {
    id:3,
    imgSrc: B3,
  subtitle: "COMING SOON",
    title: "WEARABLES",
    title2:
      "The Range of Collection",
    buttonLink: "/contact",
    buttonText: "Learn More",
    bgColor: "orange",
  },
  
  // Add more slides as needed
];

const Banner = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    cssEase: "ease-in-out",
    pauseOnHover: true,
    pauseOnFocus: true,
    };
    
     return (
      <>
      <div
        className=" flex justify-center items-center py-4 sm:py-4 overflow-hidden"
      >
        <div className="container">
          {/*Hero section*/}
          <Slider {...settings}>
            {HeroData.map((data) => (
              <div key={data.id}>
                <div style={{backgroundColor: data.bgColor}} className="grid grid-cols-1 md:grid-cols-2 hero-bg-color h-[800px]  sm:h-[580px] rounded-3xl"> 
                  {/*text content section*/}
                  <div
                    className="flex flex-col justify-center gap-4 px-4 sm:pl-[72px] pl-6 text-center sm:text-left order-2 sm:order-1  z-10"
                  >     
                    <h1 data-aos="zoom-out"  data-aos-duration="500" data-aos-once="true" className="text-2xl sm:text-6xl lg:text-2xl font-bold">
                      {" "}
                      {data.subtitle}
                    </h1>
                    <h1 data-aos="zoom-out" data-aos-duration="500" data-aos-once="true"  
                    className="text-5xl sm:text-6xl lg:text-7xl font-bold">
                      {" "}
                      {data.title}
                    </h1>
                    <h3 data-aos="zoom-out" data-aos-duration="500" data-aos-once="true" 
                      className=" text-3xl sm:text-4xl lg:text-5xl font-bold sm:tex[80px] gap-2 "
                    >
                      {" "}
                      {data.title2}
                    </h3>
                      <div
                      data-aos="zoom-in" data-aos-offset="0" data-aos-duration="500" data-aos-delay="300"
                      >
                        <Link to={data.buttonLink}>
                          <button
                            className="bg-primary text-white hover:text-white dark:hover:text-white cursor-pointer hover:scale-105   py-3 px-6 rounded-3xl relative z-20  text-lg font-bodyFont  hover:bg-black duration-300 font-bold"
                          >
                            {data.buttonText}
                          </button>
                        </Link>
                      </div>
                </div>
  
                    {/*image  section*/}
                    <div className="order-1 sm:order-2 m:mb-0 sm:ml-8">
                      <div
                      data-aos="zoom-in"  data-aos-once="true" className="relative z-10"
                      >
                        <img src={data.imgSrc} 
                          alt="SmartPods "
                          className="w-[400px] sm:w-[460px]  lg:scale-100  drop-shadow-[-8px_4px_6px_rgba(0,0,0,.4)] relative z-40"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
           </Slider>
         </div>
     </div>
    </>
  );
};

export default Banner;
