import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import React,{ useEffect, useState } from "react";
import Footer from "./components/home/Footer/Footer";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import NotFound from "./404/NotFound";
import Offer from "./pages/Offer/Offer";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";





const Layout = () => {
  const[theme, setTheme] = useState("light");
  
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  // set theme to local storage and html element
  useEffect(() => {
    localStorage.setItem("theme", theme);

    const element =document.documentElement; // access to html element 

    if (theme === "dark") {
        element.classList.add("dark");
        element.classList.add("dark");
    } else {
        element.classList.remove("light");
        element.classList.remove("dark");
    }
  }, [theme]);

useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 100,
      offset: 100,
    });
    AOS.refresh();
  }, []);

  return (

    <div className={'container ${theme}'} >
    <div className="bg-white dark:bg-gray-900 dark:text-white duration-200 overflow-hidden" >
  
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
       <Header theme={theme} setTheme={setTheme} />
      <HeaderBottom />
      <ScrollRestoration />
      <Outlet context={{theme, setTheme}}/>
      <Footer />
    
      </div>
   </div>
 
  );
};
const router = createBrowserRouter(
  createRoutesFromElements( 
   <Route>
      <Route path="/" element={<Layout />} errorElement={<NotFound />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route exact path="/shop" element={<Shop />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/contact" element={<Contact />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        <Route exact path="/category/:category" element={<Offer />}></Route>
        <Route exact path="/product/:_id" element={<ProductDetails />}></Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
);

function App() {
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
      
    </div>
  );
}

export default App;

