import React, { Suspense, useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Stage, useGLTF } from '@react-three/drei';
import { LinearDisplayP3ColorSpace,ToneMappingNode, color } from 'three/webgpu';


const Model = ({ color, modelPath }) => {
  const [error, setError] = useState(null);
  const { scene } = useGLTF(modelPath, undefined, (e) => {
    console.error('Error loading model:', e);
    setError(e);
  });
  const ref = useRef();

  useFrame((state) => {
    if (ref.current) {
      const t = state.clock.getElapsedTime();
      ref.current.rotation.x = Math.cos(t / 4) / 8;
      ref.current.rotation.y = Math.sin(t / 4) / 8;
    }
  });

  React.useEffect(() => {
    if (scene) {
      console.log('Model loaded successfully:', scene);
      scene.traverse((child) => {
        if (child.isMesh && child.material) {
          child.material.color.set(color);
        }
      });
    }
  }, [scene, color]);

  if (error) {
    console.error('Error in Model component:', error);
    return <mesh><boxGeometry args={[1, 1, 1]} /><meshStandardMaterial color="red" /></mesh>;
  }

  return scene ? <primitive ref={ref} object={scene} /> : null;
};

const ThreeDModelViewer = ({color, modelPath }) => {
  return (
    <Canvas camera={{ position: [0, 0, 3]}}>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <directionalLight
        position={[-2, 5, 2]}
        intensity={2.5}
        ToneMappingNode={ToneMappingNode}
        colorSpace={LinearDisplayP3ColorSpace}
      />
      
      <Suspense fallback={null}>
        <Stage intensity={0.05} environment="">
          <Model color="color" modelPath={modelPath} />
        </Stage>
        <OrbitControls enableZoom={true} enablePan={false} />
      </Suspense>
    </Canvas>
  );
};

export default ThreeDModelViewer;
