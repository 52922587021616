import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { AiOutlineCopyright } from "react-icons/ai";
import FooterListTitle from "./FooterListTitle";


const Footer = () => {
  const handleFacebookClick = () => {
    window.open("https://www.facebook.com/nbuzzavl/", "_blank");
  };

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/nbuzzavl/", "_blank");
  };

  return (
    <div className="relative  w-full bottom-0 py-4 text-surface/75  dark:text-white/75 lg:text-left overflow-hidden">
      <div className="container fixWithGrid ">
            
                <div className=" flex items-center justify-center border-b-2 border-neutral-200  p-0 top-0 z-10 
                   first-letter: dark:border-white/10 lg:justify-between">
                
                   <div className="  px-12 hidden lg:block  py-10 text-2xl">
                    <span>Lets patch up on social networks:</span>
                  </div>

                    {/** Social network icons container */}
                  <div className="flex justify-center">
                   <ul className="flex items-start gap-6 px-">
               <button
                className="message-icon w-24 h-24  text-gray-100 hover:text-red cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-brandBlue duration-300"
                onClick={handleFacebookClick}
              >
                <FaFacebook className="w-16 h-16 bg-blue-500 rounded-full" />
               </button>
              <button
                className="message-icon w-24 h-24  text-gray-100 hover:text-white cursor-pointer text-lg rounded-xl flex justify-center items-center hover:bg-pink-500  duration-300"
                onClick={handleInstagramClick}
              >
                <FaInstagram className="w-16 h-16 bg-pink-500 rounded-xl" />
              </button>
              <a
                className="message-icon w-24 h-24  text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-brandGreen duration-300"
                href="https://wa.me/+919409767676"
                target="__blank"
              >
                <SvgWhatsApp className="w-21 h-21 bg-green-500 rounded-full" />
              </a>
                   </ul>
                  </div>
               </div>

        {/**Main container div: holds the entire content of the footer, including four sections (Logo, Products, Useful links, and Contact), with responsive styling and appropriate padding/margins. */}
           <div className="grid md:grid-cols-3 pb-13 pt-0 cursor-pointer" >  
              {/**!-- NBUZZ section --*/}
                           <div className="py-8 px-4">
                            <h1 
                             onClick={() => (window.location = "/")}
                            className="text-2xl  lg:pr-24 pt-0  font-bold  sm:text-3xl lg:text-4xl" > NBUZZ
                            </h1>
                            <p className="text-sm text-gray-400">Unveiling the Future: Our Vision</p>
                           <div className="font-titleFont text-2xl text-gray-600 dark:text-white/70
                            lg:pr-24 pt-3 duration-300 sm:text-xl">
                             “After been the most trusted brand for Pro Audio Products in
                              india, now we are here up with the Consumer range of products
                              which will a Elevate the standards experience at New
                              BenchMarks” :-
                            <br />{" "}
                           <span className="text-primary hover:underline cursor-pointer">
                             <Link to={"/about"}>
                                Hemal Bhatt, Founder - NBUZZ
                             </Link>
                           </span>
                           </div>
                           </div>
      
            
              {/*Product section links */}
             <div className="col-span-2 grid grid-cols-2 sm:grid-cols-3 md:pl-10">
                 <div className="py-8 px-4">
                    <FooterListTitle
                    title="CATEGORY"
                    className="text-xl font-titleFont font-semibold sm:text-left  justify-center  uppercase md:justify-start  "
                    />

                    <ul className="space-y-3 ">
                    <NavLink to={"category/Earbuds"} className="er-link">
                      <li className="font-titleFont text-2xl py-2 text-gray-600 dark:text-gray-400 hover:dark:text-white hover:underline decoration-[1px]  decoration-gray-500 underline-offset-2 cursor-pointer duration-300 sm:text-xl">
                        Earbuds
                      </li>
                    </NavLink>
                    <NavLink to={"category/Speakers"} className="footer-link">
                      <li className="font-titleFont text-2xl py-2 text-gray-600  dark:text-gray-400 hover:dark:text-white hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300 sm:text-xl">
                        Party Speakers
                      </li>
                    </NavLink>
                    <div className="footer-link">
                      <li className="font-titleFont text-2xl py-2 text-gray-600  dark:text-gray-400 hover:dark:text-white hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300 sm:text-xl">
                        Smart Watches
                      </li>
                    </div>
                   </ul>
                </div>
              

                  {/* second col links */}
               <div className="py-8 px-4">
                <FooterListTitle
                  title=" LINKS"
                  className="text-xl font-titleFont font-semibold sm:text-left mb-2 justify-center  uppercase md:justify-start  "
                />
             
                  <ul className="space-y-3">
                    <li  className="font-titleFont text-2xl text-gray-600 dark:text-gray-400 hover:dark:text-white hover:underline decoration-[1px] py-1   decoration-gray-500 underline-offset-2 cursor-pointer duration-300 sm:text-xl  ">
                      <NavLink to={"/"} className="footer-link">
                        Home
                      </NavLink>
                    </li>
                    <li  className="font-titleFont text-2xl text-gray-600 dark:text-gray-400 hover:dark:text-white hover:underline decoration-[1px] py-1   decoration-gray-500 underline-offset-2 cursor-pointer duration-300 sm:text-xl  ">
                      <NavLink to={"/shop"} >
                        Product
                      </NavLink>
                    </li>

                    <li  className="font-titleFont text-2xl text-gray-600 dark:text-gray-400 hover:dark:text-white hover:underline decoration-[1px] py-1   decoration-gray-500 underline-offset-2 cursor-pointer duration-300  sm:text-xl ">
                      <NavLink to={"/about"} >
                        About
                      </NavLink>
                    </li>

                    <li  className="font-titleFont text-2xl text-gray-600 dark:text-gray-400 hover:dark:text-white hover:underline decoration-[1px] py-1  decoration-gray-500 underline-offset-2 cursor-pointer duration-300 sm:text-xl ">
                      <NavLink to={"/contact"} >
                        Contact
                      </NavLink>
                    </li>
                  </ul>
            
               </div>
               {/**company address */}
               <div className=" pt-8 pb-0  col-span-2 sm:col-auto">
                <FooterListTitle
                  title="ADDRESS"
                  className="text-xl font-titleFont font-bold sm:text-left mb-3 md:justify-start  "
                />
                <div>
                  <div  className="font-titleFont text-sm   text-gray-600 dark:text-white/70 sm:py-1  duration-300 sm:text-xl   ">
                    NBUZZ AVL LIMITED
                <br />
                    Shed No.77,
                    <br />
                     Raghuvir Industrial Estate -3,
                  <br />
                    Vatwa Station Road, Daskroi,
                  <br />   
                    AHMEDABAD Gujarat 382445 India
                  </div>
                      </div>
                 <div className=" pt-8 sm:pt-8 pb-0 ">
                  <FooterListTitle
                  title=" EMAIL"
                  className="text-xl font-titleFont font-semibold sm:text-left mb-0 "
                  />
                   <div className="flex items-end  mb-4 ">
                     <p className=" text-xl  text-gray-600 dark:text-white/70">
                        info@nbuzzavl.com
                     </p>
                   </div>
                </div>
               </div>
             </div>
           </div>
        </div>
                   {/**copyright section */}

            <div className="text-center p-6">
            <span className="text-md mr-[1px] mt-[2px] md:mt-0 text-center hidden md:inline-flex">
                <AiOutlineCopyright />
            </span>
            Copyright| NBUZZ AVL LIMITED | All Rights Reserved |
            <NavLink to="/policies/terms.html" target="_blank" rel="noopener">
                Terms &amp; Conditions
            </NavLink>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <NavLink to="/policies/privacy.html" target="_blank" rel="noopener">
                Privacy Policy
            </NavLink>
            &nbsp;&nbsp;|&nbsp;&nbsp;
                Warranty Statement

        </div>
    </div>
  );
};

function SvgWhatsApp() {
  return (
    <svg
      alt={"message image"}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1219.547 1225.016"
      width="55px"
      height="55px"
    >
      <path
        fill="#E0E0E0"
        d="M1041.858 178.02C927.206 63.289 774.753.07 612.325 0 277.617 0 5.232 272.298 5.098 606.991c-.039 106.986 27.915 211.42 81.048 303.476L0 1225.016l321.898-84.406c88.689 48.368 188.547 73.855 290.166 73.896h.258.003c334.654 0 607.08-272.346 607.222-607.023.056-162.208-63.052-314.724-177.689-429.463zm-429.533 933.963h-.197c-90.578-.048-179.402-24.366-256.878-70.339l-18.438-10.93-191.021 50.083 51-186.176-12.013-19.087c-50.525-80.336-77.198-173.175-77.16-268.504.111-278.186 226.507-504.503 504.898-504.503 134.812.056 261.519 52.604 356.814 147.965 95.289 95.36 147.728 222.128 147.688 356.948-.118 278.195-226.522 504.543-504.693 504.543z"
      />
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="609.77"
        y1="1190.114"
        x2="609.77"
        y2="21.084"
      >
        <stop offset="0" stopColor="#20b038" />
        <stop offset="1" stopColor="#60d66a" />
      </linearGradient>
      <path
        fill="url(#a)"
        d="M27.875 1190.114l82.211-300.18c-50.719-87.852-77.391-187.523-77.359-289.602.133-319.398 260.078-579.25 579.469-579.25 155.016.07 300.508 60.398 409.898 169.891 109.414 109.492 169.633 255.031 169.57 409.812-.133 319.406-260.094 579.281-579.445 579.281-.023 0 .016 0 0 0h-.258c-96.977-.031-192.266-24.375-276.898-70.5l-307.188 80.548z"
      />
      <image
        overflow="visible"
        opacity=".08"
        width="682"
        height="639"
        xlinkHref="FCC0802E2AF8A915.png"
        transform="translate(270.984 291.372)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        d="M462.273 349.294c-11.234-24.977-23.062-25.477-33.75-25.914-8.742-.375-18.75-.352-28.742-.352-10 0-26.25 3.758-39.992 18.766-13.75 15.008-52.5 51.289-52.5 125.078 0 73.797 53.75 145.102 61.242 155.117 7.5 10 103.758 166.266 256.203 226.383 126.695 49.961 152.477 40.023 179.977 37.523s88.734-36.273 101.234-71.297c12.5-35.016 12.5-65.031 8.75-71.305-3.75-6.25-13.75-10-28.75-17.5s-88.734-43.789-102.484-48.789-23.75-7.5-33.75 7.516c-10 15-38.727 48.773-47.477 58.773-8.75 10.023-17.5 11.273-32.5 3.773-15-7.523-63.305-23.344-120.609-74.438-44.586-39.75-74.688-88.844-83.438-103.859-8.75-15-.938-23.125 6.586-30.602 6.734-6.719 15-17.508 22.5-26.266 7.484-8.758 9.984-15.008 14.984-25.008 5-10.016 2.5-18.773-1.25-26.273s-32.898-81.67-46.234-111.326z"
      />
      <path
        fill="#FFF"
        d="M1036.898 176.091C923.562 62.677 772.859.185 612.297.114 281.43.114 12.172 269.286 12.039 600.137 12 705.896 39.633 809.13 92.156 900.13L7 1211.067l318.203-83.438c87.672 47.812 186.383 73.008 286.836 73.047h.255.003c330.812 0 600.109-269.219 600.25-600.055.055-160.343-62.328-311.108-175.649-424.53zm-424.601 923.242h-.195c-89.539-.047-177.344-24.086-253.93-69.531l-18.227-10.805-188.828 49.508 50.414-184.039-11.875-18.867c-49.945-79.414-76.312-171.188-76.273-265.422.109-274.992 223.906-498.711 499.102-498.711 133.266.055 258.516 52 352.719 146.266 94.195 94.266 146.031 219.578 145.992 352.852-.118 274.999-223.923 498.749-498.899 498.749z"
      />
    </svg>
  );
}


export default Footer;
